<template>
  <div class="Mfooter">
    <div class="MFooterIcon">
      <img-item imgName="编组" />
    </div>
    <div class="MFooterName">多创信息科技（泉州）有限公司</div>
    <div class="MFooterCode">
      <img-item imgName="二维码" />
    </div>
    <div class="MFooterCodeText">扫码关注我们</div>
    <div class="MFooterBottom">
      <div class="MFooterRecord" @click="linkto">备案号：闽ICP备2020021725号-1</div>
      <div class="MFooterRecord">Copyright 2020 DUOCHUANG ALL Rights Reserved</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    linkto(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index','_blank') 
    }
  },
};
</script>

<style scoped>
.Mfooter {
  padding: 1.306667rem 0 0;
  width: 100%;
  background: #16181b;
  color: #fff;
  text-align: center;
}
.MFooterIcon >>> img{
  width: 2.8rem;
  height: 1.733333rem;
}
.MFooterName {
  font-size: 0.266667rem;
  line-height: 0.36rem;
  letter-spacing: 0.013333rem;
  margin: .28rem 0 .706667rem;
  opacity: 0.5;
}
.MFooterCode >>> img{
  width: 2.4rem;
  height: 2.4rem;
}
.MFooterCodeText {
  font-size: 0.32rem;
  line-height: 0.44rem;
  margin: .266667rem 0 .773333rem;
  letter-spacing: 0.013333rem;
}
.MFooterBottom {
  padding: .266667rem 0;
  background: #131517;
}
.MFooterRecord {
  font-size: 0.266667rem;
  opacity: 0.5;
  line-height: 0.36rem;
  letter-spacing: 0.013333rem;
}
</style>
