<template>
  <div id="MApp">
    <div class="Mheader">
      <img-item imgName="编组 (1)" />
      <van-icon
        name="wap-nav"
        v-if="!showPopup"
        class="MheaderRightIcon"
        @click="topPopup"
      />
      <van-icon
        name="cross"
        v-if="showPopup"
        class="MheaderRightIcon"
        @click="topPopup"
      />
    </div>
    <router-view class="Mcenter" />
    <footer-part />

    <div id="components-back-top-demo-custom">
      <a-back-top>
        <div class="ant-back-top-inner">
          <a-icon type="arrow-up" />
        </div>
        <div class="ant-back-top-innered Medium" v-on:click.stop="doThis">
          <a-icon type="message" style="color: #2b7bed" />
          <div>联</div>
          <div>系</div>
          <div>我</div>
          <div>们</div>
        </div>
      </a-back-top>
    </div>

    <van-popup
      v-model="showPopup"
      position="top"
      style="margin-top: 1.306666rem"
    >
      <div class="contentTop Medium">
        <div :class="selectIndex == index? 'selectColor' : ''" v-for="(item,index) in navigationList" :key="index" @click="changeContent(index)">{{item.text}}</div>
      </div>
    </van-popup>
    <van-popup v-model="show" position="bottom">
      <div class="content">
        <div class="colseButton" @click="show = false">关闭</div>
        <div class="colseView">
          <div class="colseTitle Medium">联系我们</div>
          <div class="colseText">联系我们，获得更专业的服务！</div>
          <div class="colseTel Medium">电话 ： 13661377101</div>
          <div class="colseBottomButton Medium">
            <a :href="'tel:' + 13661377101">拨打电话</a>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import FooterPart from "@/components/Mfooter/index";
export default {
  components: {
    FooterPart,
  },
  data() {
    return {
      toped: 0.5,
      selectIndex: 0,
      show: false,
      showPopup: false,
      navigationList: [
        {
          path: "/mobile/home",
          text: "首页",
        },
        {
          path: "/mobile/case",
          text: "更多案例",
        },
        {
          path: "/mobile/about",
          text: "关于我们",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    changeContent(index) {
      this.selectIndex = index;
      this.$router.push(this.navigationList[index].path);
      this.topPopup()
    },
    doThis() {
      this.show = true;
    },
    topPopup() {
      this.showPopup = !this.showPopup;
    },
  },
};
</script>

<style>
#MApp {
  position: relative;
  width: 100%;
}
#components-back-top-demo-custom .ant-back-top {
  bottom: 10rem;
  right: 0.133333rem;
}
#components-back-top-demo-custom .ant-back-top-inner {
  width: 1.333333rem;
  height: 1.333333rem;
  line-height: 1.333333rem;
  font-size: 0.44rem;
  color: #747f92;
  text-align: center;
  background: #fff;
}
#components-back-top-demo-custom .ant-back-top-innered {
  margin-top: 0.266667rem;
  width: 1.333333rem;
  padding: 0.333333rem 0;
  line-height: 0.6rem;
  font-size: 0.426667rem;
  color: #3d3e4a;
  text-align: center;
  background: #fff;
}
.Mheader {
  position: fixed;
  z-index: 2014;
  height: 1.306667rem;
  width: 100%;
  line-height: 1.306667rem;
  display: flex;
  background: #000;
  justify-content: space-between;
  align-items: center;
}
.Mheader img {
  width: 4.413333rem;
  height: 0.533333rem;
  margin: 0 0.4rem;
}
.MheaderRightIcon {
  font-size: 0.666667rem;
  color: #fff;
  margin: 0 0.4rem;
}
.Mcenter {
  width: 100%;
}
.colseButton {
  font-size: 0.4rem;
  color: #999999;
  text-align: right;
  line-height: 0.56rem;
  padding: 0.533333rem 0.533333rem 0.266667rem;
}
.colseView {
  padding: 0 1.04rem;
}
.colseTitle {
  font-size: 0.64rem;
  color: #000000;
  line-height: 0.866667rem;
  letter-spacing: 0.04rem;
}
.colseText {
  font-size: 0.4rem;
  color: #333333;
  margin: 0.4rem 0 0.666667rem;
  line-height: 0.56rem;
  letter-spacing: 0.026667rem;
}
.colseTel {
  width: 100%;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  font-size: 0.533333rem;
  color: #2b7bed;
  background: #f5f6fb;
  border-radius: 0.266667rem;
}
.colseBottomButton {
  width: 100%;
  height: 1.2rem;
  line-height: 1.2rem;
  border-radius: 0.133333rem;
  margin: 0.666667rem 0 0.586667rem;
  color: #fff;
  letter-spacing: 0.026667rem;
  font-size: 0.453333rem;
  text-align: center;
  background: #2b7bed;
}
.colseBottomButton a {
  color: #fff;
}
.contentTop {
  background: #16181b;
  padding: 0.6rem;
  text-align: center;
  color: #fff;
}
.contentTop div {
  font-size: 0.48rem;
  line-height: 0.666667rem;
  letter-spacing: 0.026667rem;
  margin-bottom: 0.933333rem;
}
.selectColor {
  color: #2b7bee;
}
</style>
